/* Hover effect on CMS ads */

.marketing-content-preview--click-area:hover .marketing-content-preview--text {
    text-decoration: underline;
}

.other-verticals-link {
    position: relative;
}

.other-verticals-link a::before {
    content: "";
    position: absolute;
    inset: 0;
}

.other-verticals-link:focus-within {
    outline: 2px solid #0063fb;
}

.other-verticals-link:focus-within a {
    outline: none;
}

@keyframes scale-in {
    0% {
        opacity: 0;
        transform: translate(-50%, -25%) scaleY(0.5) scaleX(0.25);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
    }
}
